<template>
  <div class="time-table">
    <div class="whole">
      <div
        class="list"
        v-for="timeTableRowData in timeTableData"
        :key="timeTableRowData.id"
      >
        <time-table-row
          :timeTableRowData="timeTableRowData"
          :reservationTime="reservationTime"
          :timeUnit="timeUnit"
          :onDateFlg="onDateFlg"
          @time-table-row-click="emitItemData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TimeTableRow from '@/components/parts/molecules/TimeTableRow.vue'

export default {
  name: 'TimeTable',

  components: {
    TimeTableRow
  },

  props: {
    timeTableData: { type: Array },
    reservationTime: { type: Object },
    timeUnit: { type: Number },
    onDateFlg: { type: Boolean }
  },

  methods: {
    emitItemData(timeTableRowItemData) {
      this.$emit('time-table-click', timeTableRowItemData)
    }
  }
}
</script>

<style lang="scss" scoped>
.time-table {
  > .whole {
    > .list {
      margin-bottom: 18px;
    }
  }
}
@media (max-width: 640px) {
  .time-table {
    > .whole {
      > .list {
        margin-bottom: 13px;
      }
    }
  }
}
</style>
