<template>
  <div class="time-table-row-item">
    <button
      class="time-table-row-item-button"
      :class="{ reserved: reservedFlg }"
      :disabled="!timeTableRowItemData.available"
      @click="emitClickEvent"
      data-test="time-panel"
      data-e2e="time-table-row-item"
    >
      {{ this.displayTime }}～
    </button>
  </div>
</template>

<script>
export default {
  name: 'TimeTableRowItem',

  props: {
    timeTableRowItemData: {
      time: { type: String },
      available: { type: Boolean },
      reserved: { type: Boolean }
    },
    reservationTime: {
      type: Object,
      require: false,
      default: () => ({ startTime: '0000', endTime: '0000' })
    },
    timeUnit: { type: Number, require: false, default: 0 },
    onDateFlg: { type: Boolean, require: false, default: false }
  },

  computed: {
    displayTime() {
      const hours = this.timeTableRowItemData.time.slice(0, 2)
      const minutes = this.timeTableRowItemData.time.slice(2, 4)
      const time = `${hours}:${minutes}`
      return time
    },
    reservedFlg() {
      if (this.reservationTime && this.onDateFlg) {
        const time = this.timeTableRowItemData.time
        const nextTime = this.toHHMM(this.toMinutes(time) + this.timeUnit)
        const start = this.reservationTime.startTime
        const end = this.reservationTime.endTime
        if (!(end <= time) && !(nextTime <= start)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },

  methods: {
    emitClickEvent() {
      if (this.timeTableRowItemData.available)
        this.$emit('time-table-row-item-click')
      else {
        return
      }
    },
    toMinutes(HHMM) {
      return Number(HHMM.slice(0, 2)) * 60 + Number(HHMM.slice(2, 4))
    },
    toHHMM(minutes) {
      const HH = ('0' + Math.floor(minutes / 60)).slice(-2)
      const MM = ('0' + (minutes - HH * 60)).slice(-2)
      const HHMM = `${HH}${MM}`
      return HHMM
    }
  }
}
</script>

<style lang="scss" scoped>
.time-table-row-item {
  width: 100%;
  margin: 0 auto;
  > .time-table-row-item-button {
    @include font-size(18);
    cursor: pointer;
    width: 100%;
    height: 42px;
    border-radius: 6px;
    border: solid 2px #{$plus_be_gray};
    background-color: #{$plus_white};
    font-weight: bold;
    color: #{$plus_black};
    &:disabled {
      pointer-events: none;
      border: solid 2px #{$plus_f7_gray};
      background-color: #{$plus_f7_gray};
      font-weight: normal;
      color: #{$plus_be_gray};
    }
    &.reserved {
      border: solid 2px #{$plus_orange};
      background-color: #{$plus_orange};
      font-weight: bold;
      color: #{$plus_white};
    }
    &:hover {
      opacity: 0.8;
      border: solid 2px #{$plus_orange};
      background-color: #{$plus_orange};
      color: #{$plus_white};
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 40px;
    }
    &:disabled.reserved {
      opacity: 0.4;
    }
  }
}
@media (max-width: 640px) {
  .time-table-row-item {
    > .time-table-row-item-button {
      font-size: 0.9rem;
    }
  }
}
</style>
