<template>
  <div class="time-table-row">
    <div class="whole" v-if="!split">
      <div class="hour-area">
        <div class="hour" data-test="hour">{{ this.timeTableRowHours }}</div>
      </div>
      <div class="button-area">
        <ul class="row">
          <li
            class="row-item"
            v-for="timeTableRowItemData in timeTableRowData"
            :key="timeTableRowItemData.id"
          >
            <time-table-row-item
              :timeTableRowItemData="timeTableRowItemData"
              :reservationTime="reservationTime"
              :timeUnit="timeUnit"
              :onDateFlg="onDateFlg"
              @time-table-row-item-click="emitItemData(timeTableRowItemData)"
            />
          </li>
        </ul>
      </div>
    </div>
    <!-- { テーブルアイテム数 > 4 } かつ { ウィンドウ幅 <= 450px } の場合は2段に分割して表示 -->
    <div class="whole" v-if="split">
      <div class="hour-area">
        <div class="hour" data-test="hour-split">
          {{ this.timeTableRowHours }}
        </div>
      </div>
      <div class="button-area">
        <ul class="row upper">
          <li
            class="row-item"
            v-for="splitTimeTableRowItemData in splitTimeTableRowData[0]"
            :key="splitTimeTableRowItemData.id"
          >
            <time-table-row-item
              :timeTableRowItemData="splitTimeTableRowItemData"
              :reservationTime="reservationTime"
              :timeUnit="timeUnit"
              :onDateFlg="onDateFlg"
              @time-table-row-item-click="
                emitItemData(splitTimeTableRowItemData)
              "
            />
          </li>
        </ul>
        <ul class="row lower">
          <li
            class="row-item"
            v-for="splitTimeTableRowItemData in splitTimeTableRowData[1]"
            :key="splitTimeTableRowItemData.id"
          >
            <time-table-row-item
              :timeTableRowItemData="splitTimeTableRowItemData"
              :reservationTime="reservationTime"
              :timeUnit="timeUnit"
              :onDateFlg="onDateFlg"
              @time-table-row-item-click="
                emitItemData(splitTimeTableRowItemData)
              "
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TimeTableRowItem from '@/components/parts/atoms/TimeTableRowItem.vue'
import moment from 'moment'

export default {
  name: 'TimeTableRow',

  components: {
    TimeTableRowItem
  },

  props: {
    timeTableRowData: { type: Array },
    reservationTime: { type: Object },
    timeUnit: { type: Number },
    onDateFlg: { type: Boolean }
  },

  data() {
    return {
      splitTimeTableRowData: [],
      split: false
    }
  },

  computed: {
    timeTableRowHours() {
      const HH = this.timeTableRowData[0].time.slice(0, 2)
      return this.$i18n.locale === 'en' ? `${HH}:00, …` : `${HH}時`
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.splitArray()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    emitItemData(timeTableRowItemData) {
      this.$emit('time-table-row-click', timeTableRowItemData)
    },
    handleResize() {
      window.screen.width <= 450 && this.timeTableRowData.length > 4
        ? (this.split = true)
        : (this.split = false)
    },
    splitArray() {
      const arrayList = []
      const list = Array.from(this.timeTableRowData)
      if (list.length > 4) {
        const halfArrayLengthNum = Math.floor(list.length / 2)
        arrayList.push(list.splice(0, halfArrayLengthNum))
        arrayList.push(list.splice(0, halfArrayLengthNum))
        this.splitTimeTableRowData = arrayList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.time-table-row {
  > .whole {
    width: 717px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    > .hour-area {
      width: 13%;
      > .hour {
        width: 100%;
        height: 42px;
        @include font-size(18);
        border-radius: 6px;
        background-color: #{$plus_f7_gray};
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    > .button-area {
      width: 87%;
      > .row {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
        > .row-item {
          width: 100%;
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          margin-left: 12px;
          margin-bottom: 12px;
        }
      }
    }
  }
}
@media (max-width: 960px) {
  .time-table-row {
    > .whole {
      width: 640px;
    }
  }
}
@media (max-width: 640px) {
  .time-table-row {
    @include selector-width('.whole', 620px, 640px);
    > .whole {
      > .hour-area {
        > .hour {
          font-size: calc(100vw / 36);
        }
      }
    }
  }
}
</style>
