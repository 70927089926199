<template>
  <div class="reservation-time-select">
    <base-loading :waitFlg="waitFlg" />
    <div class="page-title">
      <base-page-title>{{
        $t('reservationTimeSelect.pageTitle')
      }}</base-page-title>
    </div>
    <div class="reservation-progress-bar">
      <progress-bar v-bind="progressBarData" />
    </div>
    <div class="reservation-time-table">
      <div class="date" data-test="date">{{ displayReservationDate }}</div>
      <time-table
        :timeTableData="reservationAvailableTimes"
        :reservationTime="originalChangeReservationTime"
        :timeUnit="timeUnit"
        :onDateFlg="onDateFlg"
        @time-table-click="pushToNext"
      />
    </div>
    <div class="back-button">
      <base-cancel-button @click="backPage">{{
        $t('common.buttonReturn')
      }}</base-cancel-button>
    </div>
    <announce-popup
      v-if="alertFlg"
      @close="alertFlg = false"
      :title="$t('common.error')"
      :buttons="buttons"
      :type="'failure'"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import BaseLoading from '@/components/parts/atoms/BaseLoading.vue'
import TimeTable from '@/components/parts/organisms/TimeTable.vue'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import moment from 'moment'
import { getAxiosObject } from '@/utils/library'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import { createProgressBarDataForReservation } from '@/utils/createProgressBarData'

export default {
  name: 'ReservationTimeSelect',

  components: {
    BasePageTitle,
    ProgressBar,
    TimeTable,
    BaseCancelButton,
    AnnouncePopup,
    BaseLoading
  },

  mixins: [ReloadConfirm],

  data() {
    return {
      reservationAvailableTimes: [],
      alertFlg: false,
      popupMessage: '',
      buttons: [],
      progressBarData: {},
      waitFlg: false
    }
  },

  computed: {
    scenario() {
      return this.$store.getters['auth/scenario']
    },
    reservationDate() {
      if (
        this.scenario === 'first_reservation' ||
        this.scenario === 'new_reservation'
      ) {
        return this.$store.getters['reservation/getNewReservation'].date
      } else if (this.scenario === 'change_reservation') {
        return this.$store.getters['reservation/getChangeReservation'].date
      } else {
        return null
      }
    },
    displayReservationDate() {
      if (this.$i18n.locale === 'en') {
        moment.locale('en')
        return this.reservationAvailableTimes.length > 0
          ? moment(
              this.reservationDate + this.reservationAvailableTimes[0][0].time,
              'YYYYMMDDHHmm'
            ).format('dddd, MMMM D, YYYY, [from] hh:mm a')
          : moment(this.reservationDate, 'YYYYMMDD').format(
              'dddd, MMMM D, YYYY'
            )
      } else {
        moment.locale('ja')
        return moment(this.reservationDate, 'YYYYMMDD').format(
          'YYYY年MM月DD日（dd）'
        )
      }
    },
    reservationIds() {
      return this.scenario === 'change_reservation'
        ? this.$store.getters['reservation/getChangeReservation'].ids
        : []
    },
    reservationPurposeId() {
      return this.scenario === 'change_reservation'
        ? this.$store.getters['reservation/getChangeReservation']
            .reservationPurposeId
        : this.$store.getters['reservation/getNewReservation']
            .reservationPurposeId
    },
    changeReservation() {
      if (this.scenario === 'change_reservation') {
        return this.$store.getters['reservation/getChangeReservation']
      } else {
        return null
      }
    },
    originalChangeReservatonFirst() {
      if (this.changeReservation) {
        return this.$store.getters['reservation/getReservationById'](
          this.changeReservation.ids[0]
        )
      } else {
        return null
      }
    },
    originalChangeReservationTime() {
      return this.originalChangeReservatonFirst
        ? {
            startTime: this.originalChangeReservatonFirst.startTime,
            endTime: this.originalChangeReservatonFirst.endTime
          }
        : null
    },
    onDateFlg() {
      return this.originalChangeReservatonFirst
        ? this.reservationDate === this.originalChangeReservatonFirst.date
          ? true
          : false
        : false
    },
    timeUnit() {
      return this.$store.getters['reservationSetting/getReservationTimeUnit']
    }
  },

  created() {
    this.progressBarData = createProgressBarDataForReservation(this.scenario, 3)
    this.setReservationAvailableTimes()
    this.$store.dispatch('reservation/resetStartTime')
  },

  methods: {
    async setReservationAvailableTimes() {
      try {
        this.waitFlg = true
        const axiosObject = getAxiosObject()
        const response = await axiosObject.post('/available-times', {
          selectedDate: this.reservationDate,
          reservationIds: this.reservationIds,
          reservationPurposeId: this.reservationPurposeId
        })
        this.reservationAvailableTimes = response.data.reservationAvailableTimes
        this.waitFlg = false
      } catch (ex) {
        this.alertFlg = true
        this.waitFlg = false
        if (ex.response?.status === 401) {
          this.buttons = [this.$t('common.buttonToLogin')]
        } else {
          this.popupMessage = this.$t(
            'common.popupMessageServerErrorReloadPage'
          )
          this.buttons = [this.$t('common.close')]
        }
      }
    },
    pushToNext(timeTableRowItemData) {
      if (this.scenario === 'first_reservation') {
        this.$store.dispatch(
          'reservation/setNewReservationTime',
          timeTableRowItemData.time
        )
        this.$router.push({ name: 'FirstReservationInputPage' })
      } else if (this.scenario === 'new_reservation') {
        this.$store.dispatch(
          'reservation/setNewReservationTime',
          timeTableRowItemData.time
        )
        this.$router.push({ name: 'ReservationInputPage' })
      } else {
        this.$store.dispatch(
          'reservation/setChangeReservationTime',
          timeTableRowItemData.time
        )
        this.$router.push({ name: 'ReservationInputPage' })
      }
    },
    backPage() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-time-select {
  > .page-title {
    margin-top: 70px;
  }
  > .reservation-progress-bar {
    margin-top: 28px;
  }
  > .reservation-time-table {
    margin-top: 118px;
    > .date {
      font-size: 20px;
      font-weight: bold;
      color: $plus_black;
      display: inline-block;
      border-bottom: 1px solid #cecece;
      padding-bottom: 7px;
      margin: 0 5px 34px;
      font-feature-settings: 'palt';
    }
  }
  > .back-button {
    margin: 94px 0;
  }
}

@media (max-width: $global-container) {
  .reservation-time-select {
    > .page-title {
      margin-top: 63px;
    }
    > .reservation-progress-bar {
      margin-top: 24px;
    }
    > .reservation-time-table {
      margin-top: 77px;
    }
    > .back-button {
      margin: 90px 0;
    }
  }
}
</style>
